import { defineComponent, ref } from 'vue';
import BaseIcon from '../atoms/BaseIcon.vue';
import VideoComponent from '../atoms/VideoComponent.vue';
export default defineComponent({
    name: 'VideoWithHeadline',
    components: { BaseIcon, VideoComponent },
    props: {
        leftPos: Boolean,
        positionPlayBtn: String,
        subheadlineColor: String,
        headlineAbove: Boolean,
        fsContentCreator: Boolean,
    },
    setup() {
        let showVideo = ref(false);
        const showVideoHandler = () => (showVideo.value = true);
        const closeVideoHandler = () => (showVideo.value = false);
        return { showVideo, showVideoHandler, closeVideoHandler };
    },
});
